.fingerprint-spinner {
  text-align: center;
  box-sizing: border-box;
  width: 64px;
  height: 64px;
  padding: 2px;
  position: relative;
  overflow: hidden;
}

.fingerprint-spinner .spinner-ring {
  border: 2px solid #0000;
  border-top-color: #fff;
  border-radius: 50%;
  margin: auto;
  animation: 1.5s cubic-bezier(.68, -.75, .265, 1.75) infinite forwards fingerprint-spinner-animation;
  position: absolute;
  inset: 0;
}

.fingerprint-spinner .spinner-ring:first-child {
  width: 6.66667px;
  height: 6.66667px;
  animation-delay: 50ms;
}

.fingerprint-spinner .spinner-ring:nth-child(2) {
  width: 13.3333px;
  height: 13.3333px;
  animation-delay: .1s;
}

.fingerprint-spinner .spinner-ring:nth-child(3) {
  width: 20px;
  height: 20px;
  animation-delay: .15s;
}

.fingerprint-spinner .spinner-ring:nth-child(4) {
  width: 26.6667px;
  height: 26.6667px;
  animation-delay: .2s;
}

.fingerprint-spinner .spinner-ring:nth-child(5) {
  width: 33.3333px;
  height: 33.3333px;
  animation-delay: .25s;
}

.fingerprint-spinner .spinner-ring:nth-child(6) {
  width: 40px;
  height: 40px;
  animation-delay: .3s;
}

.fingerprint-spinner .spinner-ring:nth-child(7) {
  width: 46.6667px;
  height: 46.6667px;
  animation-delay: .35s;
}

.fingerprint-spinner .spinner-ring:nth-child(8) {
  width: 53.3333px;
  height: 53.3333px;
  animation-delay: .4s;
}

.fingerprint-spinner .spinner-ring:nth-child(9) {
  width: 60px;
  height: 60px;
  animation-delay: .45s;
}

@keyframes fingerprint-spinner-animation {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=index.551e90f6.css.map */
